import React, { useEffect, useState } from "react";

const CountdownTimer = ({ setResendCode, resendCode }) => {
  const [time, setTime] = useState(resendCode);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    if (time === 0) {
      setResendCode(0);
    }
    return () => clearInterval(interval);
  }, [setResendCode, time]);

  return (
    <div>
      <p>Reenviar código en: {time}</p>
    </div>
  );
};

export default CountdownTimer;
