// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth  } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1Oqr9jLe8o8mKrZIOLJl2tGUrQczylG0",
  authDomain: "trivia-1c4f2.firebaseapp.com",
  projectId: "trivia-1c4f2",
  storageBucket: "trivia-1c4f2.appspot.com",
  messagingSenderId: "648189379566",
  appId: "1:648189379566:web:c0fe52e02431e2135f04ff",
  measurementId: "G-NVWNZRYHP2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app);

export default auth;