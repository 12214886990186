import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Footer = () => {
  return (
    <Fragment>
      <div className="mt-5 px-5 py-2">
        <Row>
          <Col md="3" xs="12">
            <div className="logo-container">
              <img alt="Trivia" src={require("../../assets/img/logo3.png")} />
            </div>
          </Col>
          <Col md="3" xs="6">
            <div className="footer-containers">
              <h4>Quiénes somos</h4>
              <p>
                <a
                  href="https://www.letitechsl.com/about"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text_decoration_a"
                >
                  Sobre nosotros&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </p>
              <p>
                <a
                  href="https://www.letitechsl.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text_decoration_a"
                >
                  Contáctanos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </p>
            </div>
          </Col>
          <Col md="3" xs="6">
            <div className="footer-containers">
              <h4>Android <i className="bi bi-android"></i></h4>
              <a
                href="https://trivia-web.com/trivia/api/trivia/others/app"
                download
                className="text_decoration_a text-white"
              >
                Descargar&nbsp;&nbsp;<i className="bi bi-download"></i>
              </a>
            </div>
          </Col>
          <Col md="3" xs="6">
            <div className="footer-containers">
              <h4>Síguenos</h4>
              <a
                href="https://www.instagram.com/letitech_/?utm_source=qr&igsh=MXdoY3JxeGppdXV3Yg%3D%3D"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-instagram text-white"></i>
              </a>
              &nbsp;&nbsp;
              <a
                href="https://www.facebook.com/letitech.co/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-facebook text-white"></i>
              </a>
              &nbsp;&nbsp;
              <a
                href="https://api.whatsapp.com/send/?phone=240555832451&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-whatsapp text-white"></i>
              </a>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div className="text-center">
              <p>
                <Link
                  to={{ pathname: "/terms" }}
                  className="text-white text_decoration_a "
                >
                  Términos y Política de privacidad
                </Link>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <p>
                {" "}
                &copy; {new Date().getFullYear()} Todos los derechos reservados.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Footer;
